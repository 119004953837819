import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import SplitText from 'react-pose-text'
import '@fortawesome/fontawesome-free/css/all.css'
import './hero.css'
// import kebabCase from 'lodash/kebabCase'

class HeroComponent extends React.Component {
  render() {
    const charPoses = {
      exit: { opacity: 0, y: 20 },
      enter: {
        opacity: 1,
        y: 0,
        delay: ({ charIndex }) => charIndex * 30,
      },
    }
    const { location, features, title } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let hero
    if (location.pathname === rootPath) {
      hero = (
        <div className="hero">
          <div id="hero-wave">
            <div className="hero-wave hero-wave-1"></div>
            <div className="hero-wave hero-wave-2"></div>
          </div>
          <div className="hero-sub">
            <Container>
              <Row>
                <Col className="py-5 text-center">
                  <h1 className="my-4 text-white">
                    <SplitText
                      initialPose="exit"
                      pose="enter"
                      charPoses={charPoses}
                    >
                      Websites as a Service
                    </SplitText>
                  </h1>
                  <h4 className="my-4 text-white">
                    <SplitText
                      initialPose="exit"
                      pose="enter"
                      charPoses={charPoses}
                    >
                      The answer to high-quality business websites on a budget
                    </SplitText>
                  </h4>
                  {/*<Link to="/headless-cms-sandbox" className="d-block d-sm-inline-block btn btn-primary btn-inverted">GET YOUR SANDBOX INVITE</Link>*/}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )
    } else if (
      location.pathname === '/blog' ||
      location.pathname === `/blog/`
    ) {
      hero = (
        <div id="hero-blog-container">
          <Container fluid className="p-0">
            <div className="col p-0 blog-article-grid">
              {features.map(({ node }) => {
                return (
                  <div className="blog-article main" key={node.id}>
                    <div
                      className="blog-article-cover-image lazy"
                      style={{
                        backgroundImage: `url(${node.mainImage &&
                          node.mainImage.asset &&
                          node.mainImage.asset &&
                          node.mainImage.asset.fluid &&
                          node.mainImage.asset.fluid.src})`,
                      }}
                    />
                    <Link
                      to={`/${node.slug.current}`}
                      className="blog-article-action-trigger"
                    />
                    <div
                      className="blog-article-cover-gradient"
                      style={{
                        backgroundImage: `url(/img/image-gradient.png)`,
                      }}
                    />
                    <div className="blog-article-content">
                      <h2 className="blog-article-title">
                        <Link
                          className="text-white"
                          to={`/${node.slug.current}`}
                        >
                          {node.title}
                        </Link>
                      </h2>
                      <div className="blog-article-info">
                        <ul className="m-0 list-unstyled d-flex flex-wrap">
                          {node.authors.length !== 0 && (
                            <li className="mr-3 mb-0 mb-sm-3">
                              <i className="far fa-user-circle mr-2" />
                              <Link
                                className="text-white"
                                to={`/author/${node.authors[0].person.slug.current}`}
                              >
                                {node.authors[0].person.name}
                              </Link>
                            </li>
                          )}
                          <li className="mb-0 mb-sm-3">
                            <i className="far fa-clock mr-2" />
                            {node.publishedAt
                              ? node.publishedAt
                              : node._createdAt}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Container>
        </div>
      )
    } else if (
      location.pathname === '/insights' ||
      location.pathname === '/insights/'
    ) {
      hero = (
        <div id="hero-blog-container">
          <Container fluid className="p-0">
            <div className="col p-0 blog-article-grid">
              {features.map(({ node }) => {
                return (
                  <div className="blog-article main" key={node.id}>
                    <div
                      className="blog-article-cover-image lazy"
                      style={{
                        backgroundImage: `url(${node.mainImage &&
                          node.mainImage.asset &&
                          node.mainImage.asset &&
                          node.mainImage.asset.fluid &&
                          node.mainImage.asset.fluid.src})`,
                      }}
                    />
                    <Link
                      to={`/insights/${node.slug.current}`}
                      className="blog-article-action-trigger"
                    />
                    <div
                      className="blog-article-cover-gradient"
                      style={{
                        backgroundImage: `url(/img/image-gradient.png)`,
                      }}
                    />
                    <div className="blog-article-content">
                      <h6 className="article-category text-uppercase mb-4">
                        Insights
                      </h6>
                      <h2 className="blog-article-title">
                        <Link
                          className="text-white"
                          to={`/insights/${node.slug.current}`}
                        >
                          {node.title}
                        </Link>
                      </h2>
                      <div className="blog-article-info">
                        <ul className="m-0 list-unstyled d-flex flex-wrap">
                          {node.authors.length !== 0 && (
                            <li className="mr-3 mb-0 mb-sm-3">
                              <i className="far fa-user-circle mr-2" />
                              {node.authors[0].person.name}
                            </li>
                          )}
                          <li className="mb-0 mb-sm-3">
                            <i className="far fa-clock mr-2" />
                            {node.publishedAt
                              ? node.publishedAt
                              : node._createdAt}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Container>
        </div>
      )
    } else if (
      location.pathname === '/agency' ||
      location.pathname === '/agency/'
    ) {
      hero = (
        <div className="hero-agency">
          <div className="hero-sub-agency">
            <div className="hero-sub-banner"></div>
            <Container>
              <Row>
                <Col lg={7} xl={8} className="py-5">
                  <div className="hero-content">
                    <h1 className="hero-agency-title} font-weight-normal text-white my-4">
                      Serviced Web Platform for Digital Sales Agencies
                    </h1>
                    <div className="d-block d-sm-inline-block mt-3 mx-1">
                      <a
                        href="#partnership"
                        className="btn shadow-none btn-primary btn-bordered w-100 btn btn-primary"
                      >
                        GET STARTED
                      </a>
                    </div>
                    <div className="d-block d-sm-inline-block mt-3 mx-1">
                      <a
                        href="https://res.cloudinary.com/dnla85pdq/image/upload/v1596552225/webriq/files/Why_Outsource_Your_Web_Development.pdf"
                        className="btn shadow-none btn-primary btn-inverted btn-bordered w-100"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LEARN MORE
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )
    } else if (
      location.pathname === '/headless-cms-sandbox' ||
      location.pathname === '/headless-cms-sandbox/'
    ) {
      hero = (
        <div className="hero-agency-sandbox">
          <div className="hero-sub-agency-sandbox">
            <Container>
              <Row>
                <Col lg={7} xl={10} className="py-5 mx-auto">
                  <div className="hero-content text-center">
                    <h1 className="hero-agency-title font-weight-normal text-white my-4">
                      Experience all the goodies of a Headless CMS in the WebriQ
                      SandBox
                    </h1>
                    <div className="d-block d-sm-inline-block mt-3">
                      <a
                        href="#invite"
                        className="btn btn-primary btn-inverted shadow-none w-100"
                      >
                        Get your invite now!!!
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )
    } else if (location.pathname === '/seo' || location.pathname === '/seo/') {
      hero = (
        <div className="hero">
          <div id="hero-main" className="engagement">
            <div className="hero-wave hero-wave-1" />
            <div className="hero-wave hero-wave-2" />
          </div>
          <div className="hero-sub">
            <Container>
              <Row>
                <Col className="py-5 text-center">
                  <h1 className="mt-4 text-white">
                    <SplitText
                      initialPose="exit"
                      pose="enter"
                      charPoses={charPoses}
                    >
                      SCALE YOUR BRAND with SEO-focused content marketing
                    </SplitText>
                  </h1>
                  <h5 className="mt-0 text-white">
                    Growth hack your brand to the Top of the Google rankings
                    where customers can find you.
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )
    } else {
      hero = (
        <div id="hero-main" className="hero">
          <div id="hero-wave">
            <div className="hero-wave hero-wave-1"></div>
            <div className="hero-wave hero-wave-2"></div>
          </div>
          <div className="hero-sub">
            <Container>
              <Row>
                <Col className="py-5 text-center">
                  <h1 className="my-4 text-white text-capitalize">{title}</h1>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )
    }
    return <> {hero} </>
  }
}

export default HeroComponent
